import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ContactUs, Home, Product, ProductDetail, AboutUs } from "../../pages";
import { Footer, Navbar } from "../../components";
import "./approuter.scss";

const AppRuter = () => {
  return (
    <div className="approuter-item-container">
      <BrowserRouter>
        <div className="approuter-item-header">
          <Navbar />
        </div>
        <div className="approuter-item-content">
          <Routes>
            <Route path="/*" element={<Home />} />
            <Route path="/tentang-kami" element={<AboutUs />} />
            <Route path="/menu" element={<Product />} />
            <Route path="/menu/:menu" element={<ProductDetail />} />
            <Route path="/contact-us" element={<ContactUs />} /> 
          </Routes>
        </div>
        <div className="approuter-item-footer">
          <Footer />
        </div>
      </BrowserRouter>
    </div>
  );
};

export default AppRuter;
