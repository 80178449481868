import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./dropdown.scss";

const DropDown = ({ dropdown, subMenu, setDropdown, path }) => {
  return (
    <div className={`motion-dropdown-menu ${dropdown ? "active" : "inactive"}`}>
      {subMenu.map((item, index) => {
        return (
          <Link
            to={`${path}/${item.slug}`}
            className="motion-dropdown-item"
            key={index}
            onClick={() => setDropdown(false)}
            state={item}
          >
            <div className="motion-dropdown-item-title">{item.title}</div>
          </Link>
        );
      })}
    </div>
  );
};

export default DropDown;
