export const ProductData = [
  {
    id: 1,
    slug: "ice-coffee",
    image: "new-pajero",
    title: "ICE COFFEE",
    // price: "Rp.577.600.000,-",
    subtitle:
      "Es Kopi Nikmat dengan campuran dan paduan bahan yang pas berkualitas sehingga menghasilkan es kopi yang segar dan nikmat. Siap menemani waktu berbagi anda",
    table: {
      headers: ["OUR MENU - ICE COFFEE", " "],
      rows: [
        ["Butterscoach Latte", "Americano Ice"],
        ["Cafe Latte", "Arabica Coffee Milk"],
        ["Japanese Coffee", "Dolge Latte Ice"],
        ["Sea Salt Latte", "Capuccino Ice"],
        ["Java Mocha", "Caramel Machiatto"],
        ["Affogato", "French Hazelnut Latte"],
      ],
    },
  },
  {
    id: 2,
    slug: "hot-coffee",
    image: "new-xpander",
    title: "HOT COFFEE",
    // price: "Rp.258.200.000,-",
    subtitle:
      "Kopi panas yang berasap dan harum membuat dengan cita rasa kopi yang nikmat siap untuk di santap bersama teman berbagi cerita dan pengalaman anda.",
    table: {
      headers: ["OUR MENU - HOT COFFEE", " "],
      rows: [
        ["Manual Brew", "Vietnam Drip"],
        ["Hot Americano", "Hot Espresso"],
      ],
    },
  },
  {
    id: 3,
    slug: "ice-shake",
    image: "new-xpander-cross",
    title: "ICE SHAKE",
    // price: "Rp.316.750.000,-",
    subtitle:
      "Kejutan guncangan Es Shake menjadikan campuran rasa yang nikmat dan segar untuk dinikmati kalangan anak-anak hingga dewasa.",
    table: {
      headers: ["OUR MENU - ICE SHAKE", " "],
      rows: [
        ["Chocolate Ovaltine", "Taro Latte"],
        ["Lemonade", "Blue Ocean Lemonade"],
        ["Manggo Yogurt", "Jeruk Yogurt"],
        ["Lychee Yogurt ", "Melon Yogurt"],
        ["Sakura Lemonade", " "],
      ],
    },
  },
  {
    id: 4,
    slug: "street-food",
    image: "strada-triton",
    title: "STREET FOOD",
    // price: "Rp.287.150.000,-",
    subtitle:
      "Makanan siap saji dengan bahan yang berkualitas sehingga menciptakan rasa nyummy dan nikmat bila disantap bersama dengan minuman yang hangat atau segar.",
    table: {
      headers: ["OUR MENU - STREET FOOD", " "],
      rows: [
        ["Odeng", "Odeng Chili Oil"],
        ["Paket Oden Kuah (Tahu, Chikua, Bakso, Odeng, Dumpling)", "Topokki"],
        ["Paket Oden Chili Oil (Tahu, Chikua, Bakso, Odeng, Dumpling)", "Rabokki"],
        ["Karage Original", "Karage Chili Oil"],
        ["Karage Cheese", "Kentang/French fries"],
        ["Dimsum Goreng 8 Pcs", "Dimsum Goreng Chili Oil  8 Pcs"],
        ["Sosis Jumbo Original", "Sosis Jumbo Isi Keju"],
        ["Sosis Jumbo Chili Oil", "Sosis Jumbo Isi Keju Chili Oil"],
      ],
    },
  },
  {
    id: 5,
    slug: "boba-float",
    image: "outlander-phev",
    title: "BOBA FLOAT",
    // price: "Rp.1.322.700.000,-",
    subtitle:
      "Minuman rasa segar dengan paduan ice cream dan boba caramel menciptakan kenikmatan yang tiada dua, dapat dinikmati bersama keluarga dan teman-teman bermanin anda.",
    table: {
      headers: ["OUR MENU - BOBA FLOAT", " "],
      rows: [
        ["Redvelvet", "Chocolate Ovaltine"],
        ["Green Matcha", "Brown Sugar"],
        ["Black Avocado", "Taro Latte"],
      ],
    },
  },
  {
    id: 6,
    slug: "ice-tea",
    image: "l-300",
    title: "ICE TEA",
    // price: "Rp.224.150.000,-",
    subtitle:
      "Es Tea berbagai macam rasa yang nikmat dan segar dengan cita rasa buah dan daun teh piliham menciptakan Es Tea yang segar, cocok dinikmati di iklim indonesia.",
    table: {
      headers: ["OUR MENU - ICE TEA", " "],
      rows: [
        ["Thai Tea", "Lemon Tea"],
        ["Jasmine Tea", "Blackcurrent Tea"],
        ["Lemon Honey Tea", "Lychee Tea"],
        ["Vanilla Tea", "Apple Tea"],
        ["Tamarin Tea", " "],
      ],
    },
  },
];
