import React from "react";
import "./homeslider.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { ProductData } from "../../../Data/ProductData";
import { CardSlider } from "../../atoms";
import { useNavigate } from "react-router-dom";

const HomeSlider = () => {
  const navigate = useNavigate();
  const GoToProduct = (item) => {
    navigate("/product/" + item.slug, { state: item });
  };

  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    cssEase: "linear",
    pauseOnHover: true,
    
  };
  

  return (
    <div className="homeslider-item-container">
      <div className="homeslider-item-container-content">
        <div className="homeslider-item-container-content-main">
          <Slider {...settings}>
            {ProductData.map((item, index) => {
              return (
                <CardSlider
                  key={index}
                  id={item.id}
                  image={item.image}
                  onClick={() => GoToProduct(item)}
                />
              );
            })}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default HomeSlider;
