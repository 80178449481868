import React from "react";
import "./cardslider.scss";
import {
  BannerNewXpanderCross,
  BannerNewXpander,
  BannerNewPajero,
  BannerTriton,
  BannerOutlanderPhev,
  BannerL300,
} from "../../../assets";

const CardSlider = ({ id, slug, image, title, price, subtitle, onClick }) => {
  const Image = () => {
    if (image === "new-xpander-cross") {
      return (
        <img
          src={BannerNewXpanderCross}
          className="card-slider-item-image"
          alt={`car-${id}`}
        />
      );
    } else if (image === "new-xpander") {
      return (
        <img
          src={BannerNewXpander}
          className="card-slider-item-image"
          alt={`car-${id}`}
        />
      );
    } else if (image === "new-pajero") {
      return (
        <img
          src={BannerNewPajero}
          className="card-slider-item-image"
          alt={`car-${id}`}
        />
      );
    } else if (image === "strada-triton") {
      return (
        <img
          src={BannerTriton}
          className="card-slider-item-image"
          alt={`car-${id}`}
        />
      );
    } else if (image === "outlander-phev") {
      return (
        <img
          src={BannerOutlanderPhev}
          className="card-slider-item-image"
          alt={`car-${id}`}
        />
      );
    } else if (image === "l-300") {
      return (
        <img
          src={BannerL300}
          className="card-slider-item-image"
          alt={`car-${id}`}
        />
      );
    } else {
      return (
        <img
          src={BannerNewPajero}
          className="card-slider-item-image"
          alt={`car-${id}`}
        />
      );
    }
  };

  return (
    <div className="card-slider-item" onClick={onClick}>
      <Image />
    </div>
  );
};

export default CardSlider;
