import React from 'react';
import "./aboutus.scss"

const AboutUs = () => {
  return (
    <div className='Aboutus-page-container'>
      <div className='AboutUs-page-container-content'>
        
      </div>
    </div>
  )
}

export default AboutUs