import React from "react";
import "./productdetail.scss";
import { useLocation } from "react-router-dom";
import {
  BannerL300,
  BannerNewPajero,
  BannerNewXpander,
  BannerNewXpanderCross,
  BannerOutlanderPhev,
  BannerTriton,
} from "../../assets";
import { Helmet } from "react-helmet";

const ProductDetail = () => {
  const location = useLocation();
  const data = location.state;
  // console.log(data);

  const Image = () => {
    if (data.image === "new-xpander-cross") {
      return (
        <img
          src={BannerNewXpanderCross}
          className="productdetail-page-content-main-image"
          alt={`car-${data.id}`}
        />
      );
    } else if (data.image === "new-xpander") {
      return (
        <img
          src={BannerNewXpander}
          className="productdetail-page-content-main-image"
          alt={`car-${data.id}`}
        />
      );
    } else if (data.image === "new-pajero") {
      return (
        <img
          src={BannerNewPajero}
          className="productdetail-page-content-main-image"
          alt={`car-${data.id}`}
        />
      );
    } else if (data.image === "strada-triton") {
      return (
        <img
          src={BannerTriton}
          className="productdetail-page-content-main-image"
          alt={`car-${data.id}`}
        />
      );
    } else if (data.image === "outlander-phev") {
      return (
        <img
          src={BannerOutlanderPhev}
          className="productdetail-page-content-main-image"
          alt={`car-${data.id}`}
        />
      );
    } else if (data.image === "l-300") {
      return (
        <img
          src={BannerL300}
          className="productdetail-page-content-main-image"
          alt={`car-${data.id}`}
        />
      );
    } else {
      return (
        <img
          src={BannerNewPajero}
          className="productdetail-page-content-main-image"
          alt={`car-${data.id}`}
        />
      );
    }
  };

  const ExampleTable = ({ tableData }) => {
    // console.log(tableData);
    return (
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            {tableData.headers.map((header, index) => (
              <th
                key={index}
                style={{
                  padding: "8px",
                  textAlign: "left",
                  borderBottom: "1px solid #6F4E37",
                  backgroundColor: "#6F4E37",
                  color: "white",
                }}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.rows.map((row, rowIndex) => (
            <tr key={rowIndex} style={{ backgroundColor: "#5C4033" }}>
              {row.map((cell, cellIndex) => (
                <td
                  key={cellIndex}
                  style={{
                    padding: "8px",
                    textAlign: "left",
                    borderBottom: "1px solid #6F4E37",
                  }}
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <>
      <Helmet>
        <title>{data.title} | De Savour Coffee</title>
        <meta name="description" content="De Savour Coffee" />
        <meta
          name="keywords"
          content={`${data.title}, De Savour Coffee, de savour, coffee, Cafe, beverages, Food, kopi, makanan, minuman kekinian, odeng, oden`}
        />
      </Helmet>
      <div className="productdetail-page-container">
        <div className="productdetail-page-content">
          <div className="productdetail-page-content-main">
            <Image />
            <div className="productdetail-page-content-main-title">
              {data.title}
            </div>
            <div className="productdetail-page-content-main-subtitle-container">
              <div className="productdetail-page-content-main-subtitle">
                {data.subtitle}
              </div>
              <div className="productdetail-page-content-main-table">
                <ExampleTable tableData={data.table} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetail;
