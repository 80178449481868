import React from "react";
import "./homecontact.scss";
import { ImSalesLeft, ImSalesRight } from "../../../assets";
import { HiMail, HiPhone } from "react-icons/hi";
import { FaWhatsapp } from "react-icons/fa";

const HomeContact = ({ title }) => {
  return (
    <div className="homecontact-item-container">
      <div className="homecontact-item-container-content">
        <div className="homecontact-item-container-content-main">
          <div className="homecontact-item-container-content-title">
            {title}
          </div>
          <div className="homecontact-item-container-content-sales">
            <div className="homecontact-item-container-content-sales-left">
              <img
                className="homecontact-item-container-content-sales-left-img"
                src={ImSalesLeft}
                alt="sales"
              /><p1>Coffee shop merupakan suatu tempat yang menyediakan berbagai jenis kopi serta
              minuman non alcohol dengan menyediakan suasana santai, tempat yang nyaman, dan
              dilengkapi berbagai fasilitas seperti alunan musik, tv, bacaan, koneksi internet serta
              desain interior yang menarik dan pelayanan yang ramah. Saat ini coffee shop mengalami pergeseran makna dan menyajikan
              suasanayang nyaman dan fasilitas yang lengkap sehingga menjadi tempat yang tepat
              untuk bersantai dan menghilangkan penat. Selain itu coffee shop juga banyak
              digunakan sebagai tempat pertemuan dengan rekan bisnis dan teman berbagi cerita.
              Budaya minum kopi saat ini sudah menjadi trend yang baru di kalangan
              masyarakat.</p1>
              <p2>De’ Savour Coffee adalah kedai kopi 
              yang berlokasi di Bekasi, 
              tepatnya di Tambun, Papanmas. 
              Toko ini menyajikan berbagai macam 
              jenis kopi dengan beragam rasa 
              yang lezat dan unik
              Selain itu, Kami juga 
              menawarkan berbagai makanan 
              dan minuman pendamping yang cocok
              untuk menemani secangkir kopi anda.</p2>
              
            </div>
            <div className="homecontact-item-container-content-sales-right">
              <div className="homecontact-item-container-content-sales-left-container">
                  <div className="homecontact-item-container-content-sales-left-top">Kontak Kami
                    <div className="homecontact-item-container-content-sales-left-top-item">
                      <HiMail
                        size={20}
                        className="homecontact-item-container-content-sales-left-top-icon"
                      />
                      <div className="homecontact-item-container-content-sales-left-top-title">
                        de.savour.official@gmail.com
                      </div>
                    </div>
                    <div className="homecontact-item-container-content-sales-left-top-item">
                      <HiPhone
                        size={20}
                        className="homecontact-item-container-content-sales-left-top-icon"
                      />
                      <div className="homecontact-item-container-content-sales-left-top-title">
                        +62-8555-555-3534
                      </div>
                    </div>
                    <div className="homecontact-item-container-content-sales-left-top-item">
                      <FaWhatsapp
                        size={20}
                        className="homecontact-item-container-content-sales-left-top-icon"
                      />
                      <div className="homecontact-item-container-content-sales-left-top-title">
                      +62-8555-555-3534
                      </div>
                    </div>
                  </div>
                  <div className="homecontact-item-container-content-sales-left-bottom">
                    <div className="homecontact-item-container-content-sales-left-bottom-title">
                      <h2>Our Base Camp</h2>
                      <p>Perum Papan Mas Indah Blok F28 No.2, Setiamekar, Kec. Tambun Selatan, Kabupaten Bekasi, Jawa Barat 17510, Indonesia.</p>                   
                    </div>
                  </div>
                </div>
              <img
                className="homecontact-item-container-content-sales-right-img"
                src={ImSalesRight}
                alt="sales"
              />
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeContact;
