import React from "react";
import "./footer.scss";
import { FaFacebookSquare, FaTiktok, FaWhatsapp, FaMapMarkedAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ExternalLink } from "../../helpers";
import { IcIg, IcTiktok, IcYoutube } from "../../../assets";

const Footer = () => {
  const OrderNow = () => {
    let orderLink =
      "https://api.whatsapp.com/send/?phone=6285555553534&text=Hallo+Kak,+Saya+butuh+info+tentang+produknya...&type=phone_number&app_absent=0";
    window.open(orderLink, "_blank"); //to open new page
  };

  return (
    <div className="footer-item-container">
      <div className="footer-item-content">
        <div className="footer-item-content-main">
          <div className="footer-item-content-main-item-container">
            <div className="footer-item-content-main-item">
              <div className="footer-item-content-main-item-title">Links</div>
              <div className="footer-item-content-main-item-link-container">
                <Link to="/" className="footer-item-content-main-item-link">
                  Home
                </Link>
                <Link
                  to="/menu"
                  className="footer-item-content-main-item-link"
                >
                  Our Menu
                </Link>
                <Link
                  to="/contact-us"
                  className="footer-item-content-main-item-link"
                >
                  Kontak Kami
                </Link>
                {/* <Link
                  to="/article"
                  className="footer-item-content-main-item-link"
                >
                  Artikel
                </Link> */}
              </div>
            </div>
            <div className="footer-item-content-main-item">
              <div className="footer-item-content-main-item-title">
                Quote
              </div>
              <div className="footer-item-content-main-item-subtitle">
                “Janganlah Menunggu 
                Pintu Yang Tertutup
                Untukmu Sedangkan
                Banyak Pintu Yang
                Terbuka Disekitarmu.”
              </div>
            </div>
            <div className="footer-item-content-main-item">
              <div className="footer-item-content-main-item-title">
                Komunitas
              </div>
              <div className="footer-item-content-main-item-social-container">
                <ExternalLink
                  className="footer-item-content-main-item-social"
                  href="https://www.tiktok.com/@de_savour_coffee"
                >
                  <FaTiktok
                    size={20} color="white"
                    className="footer-item-content-main-item-social-item"
                  />
                </ExternalLink>
                <ExternalLink
                  className="footer-item-content-main-item-social"
                  href="https://www.instagram.com/de_savour_coffee"
                >
                  <img
                    src={IcIg}
                    alt="link-social"
                    className="footer-item-content-main-item-social-item"
                  />
                </ExternalLink>
                {/* <ExternalLink
                  className="footer-item-content-main-item-social"
                  href="https://www.youtube.com"
                >
                  <img
                    src={IcYoutube}
                    alt="link-social"
                    className="footer-item-content-main-item-social-item"
                  />
                </ExternalLink> */}
                <ExternalLink
                  className="footer-item-content-main-item-social"
                  href="https://www.facebook.com/desavourcoffee"
                >
                  <FaFacebookSquare
                    size={30} color="white"
                    className="footer-item-content-main-item-social-item"
                  />
                </ExternalLink>
                <ExternalLink
                  className="footer-item-content-main-item-social"
                  href="https://maps.app.goo.gl/4TkEUg74J4kwvEay7"
                >
                  <FaMapMarkedAlt
                    size={30} color="white"
                    className="footer-item-content-main-item-social-item"
                  />
                </ExternalLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-item-copyright">
        <div className="footer-item-copyright-text">
          Copyright © 2024{" "}
          <Link to="/" className="footer-item-copyright-text-link">
            De Savour Coffee
          </Link>
        </div>
        <div className="footer-item-copyright-subtext">
          Salam Savour
        </div>
      </div>
      {/* <div className="footer-item-icon-wa" onClick={OrderNow}>
        <FaWhatsapp className="footer-item-icon-wa-item" size={40} />
      </div> */}
    </div>
  );
};

export default Footer;
