import React from "react";
import "./homeproduct.scss";
import { CardProduct } from "../../atoms";
import { useNavigate } from "react-router-dom";
import { ProductData } from "../../../Data/ProductData";

const HomeProduct = () => {
  const navigate = useNavigate();
  const GoToProduct = (item) => {
    navigate("/menu/" + item.slug, { state: item });
  };

  return (
    <div className="homeproduct-item-container">
      <div className="homecontact-item-container-content">
        <div className="homecontact-item-container-content-main">
          <div className="homecontact-item-container-content-title">
            Popular Menu
          </div>
          <div className="homecontact-item-container-content-product">
            {ProductData.map((item, index) => {
              return (
                <CardProduct
                  key={index}
                  id={item.id}
                  slug={item.slug}
                  image={item.image}
                  title={item.title}
                  price={item.price}
                  subtitle={item.subtitle}
                  onClick={() => GoToProduct(item)}
                />
              );
            })}            
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeProduct;
