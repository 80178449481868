import React from "react";
import "./contactus.scss";
import { Helmet } from "react-helmet";
import { HomeContact } from "../../components";

const ContactUs = () => {
  return (
    <>
      <Helmet>
        <title>Kontak Kami | De Savour Coffee</title>
        <meta name="description" content="De Savour Coffee" />
        <meta name="keywords" content="De Savour Coffee, de savour, coffee, Cafe, beverages, Food, kopi, makanan, minuman kekinian, odeng, oden" />
      </Helmet>
      <div className="contactus-page-container">
        <div className="contactus-page-content">
          <div className="contactus-page-content-main">
            {/* <div className="contactus-page-content-main-title">Kontak Kami</div> */}
            <div className="contactus-page-content-main-list">
              <HomeContact title=" " />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
