import React, { useState } from "react";
import "./navbarlink.scss";
import { Link } from "react-router-dom";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import DropDown from "../DropDown";

const NavbarLink = ({ key, title, path, subMenu, setClick }) => {
  const [dropdown, setDropdown] = useState(false);
  const width = window.innerWidth;

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  const OrderNow = () => {
    let orderLink =
      "https://api.whatsapp.com/send/?phone=6285555553534&text=Hallo+Kak,+Saya+butuh+tentang+produknya...&type=phone_number&app_absent=0";
    window.open(orderLink, "_blank"); //to open new page
  };

  const ClickMobile = () => {
    if (width < 600) {
      setClick(false);
    }
  };

  return (
    <>
      {!subMenu ? (
        title !== "More Info" ? (
          <div className="navbarlink-item-container">
            <Link
              to={path}
              className="navbarlink-item-text"
              onClick={ClickMobile}
            >
              {title}
            </Link>
          </div>
        ) : (
          <div className="navbarlink-item-container">
            <div className="navbarlink-item-order" onClick={OrderNow}>
              <Link
                to={path}
                className="navbarlink-item-order-text"
                onClick={ClickMobile}
              >
                {title}
              </Link>
            </div>
          </div>
        )
      ) : (
        <div
          className="navbarlink-item-container"
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <Link
            to={path}
            className="navbarlink-item-text"
            onClick={ClickMobile}
          >
            {title}
            {subMenu && width >= 700 ? (
              dropdown ? (
                <FaCaretUp className="navbarlink-item-icon" />
              ) : (
                <FaCaretDown className="navbarlink-item-icon" />
              )
            ) : null}
          </Link>
          <DropDown
            dropdown={dropdown}
            subMenu={subMenu}
            setDropdown={setDropdown}
            path={path}
          />
        </div>
      )}
    </>
  );
};

export default NavbarLink;
