import React from "react";
import { useNavigate } from "react-router-dom";
import "./home.scss";
// import {Bgpage} from "../../assets";
import {
  HomeArticle,
  HomeBrand,
  HomeContact,
  HomeProduct,
  HomeSlider,
} from "../../components";

const Home = () => {
  const navigate = useNavigate();

  const goToPage = (page) => {
    navigate(page);
  };

  return (
    <div className="home-page-container">
      {/* <img src={Bgpage} style={{
      resizeMode: "contain",
      width: "100%",
      position: "absolute"
      }}/> */}
      <HomeSlider />
      <HomeProduct />
      <HomeBrand />
      <HomeContact title=" " />
      {/* <HomeArticle /> */}
    </div>
  );
};

export default Home;
