import React, { useState } from "react";
import "./navbar.scss";
import { NavbarLogo, NavbarLogoMobile } from "../../../assets";
import { NavbarLink } from "../../atoms";
import { FaBars, FaTimes } from "react-icons/fa";
import { ProductData } from "../../../Data/ProductData";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => {
    setClick(!click);
  };

  const MenuData = [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "Our Menu",
      path: "/menu",
      subMenu: ProductData,
    },
    {
      title: "Kontak Kami",
      path: "/contact-us",
    },
    {
      title: "More Info",
      path: "/contact-us",
    },
  ];

  return (
    <div className="navbar-item-container">
      <div className="navbar-item-content">
        <div className="navbar-content-logo-container">
          <img
            src={NavbarLogo}
            className="navbar-content-logo"
            alt="navbar-logo"
          />
          <img
            src={NavbarLogoMobile}
            className="navbar-content-logo-mobile"
            alt="navbar-logo"
          />
        </div>
        <div className="navbar-content-menu">
          {MenuData.map((item, index) => {
            return (
              <NavbarLink
                key={index}
                title={item.title}
                path={item.path}
                subMenu={item.subMenu}
              />
            );
          })}
        </div>
        <div className="navbar-content-bars" onClick={handleClick}>
          {click ? (
            <FaTimes className="navbar-content-bars-icon" size={25} />
          ) : (
            <FaBars className="navbar-content-bars-icon" size={25} />
          )}
        </div>
        <div
          className={`navbar-content-menu-mobile ${
            click ? "active" : "inactive"
          }`}
        >
          {MenuData.map((item, index) => {
            return (
              <NavbarLink
                key={index}
                title={item.title}
                path={item.path}
                subMenu={item.subMenu}
                setClick={setClick}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
