import React from "react";
import "./product.scss";
import { useNavigate } from "react-router-dom";
import { ProductData } from "../../Data/ProductData";
import { CardProduct } from "../../components";
import { Helmet } from "react-helmet";

const Product = () => {
  const navigate = useNavigate();
  const GoToProduct = (item) => {
    navigate("/menu/" + item.slug, { state: item });
  };

  return (
    <>
      <Helmet>
        <title>All Menu | De Savour Coffee</title>
        <meta name="description" content="De Savour Coffee" />
        <meta name="keywords" content="De Savour Coffee, de savour, coffee, Cafe, beverages, Food, kopi, makanan, minuman kekinian, odeng, oden" />
      </Helmet>
      <div className="product-page-container">
        <div className="product-page-content">
          <div className="product-page-content-main">
            <div className="product-page-content-main-title">All Menu</div>
            <div className="product-page-content-main-list">
              {ProductData.map((item, index) => {
                return (
                  <CardProduct
                    key={index}
                    id={item.id}
                    slug={item.slug}
                    image={item.image}
                    title={item.title}
                    price={item.price}
                    subtitle={item.subtitle}
                    onClick={() => GoToProduct(item)}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Product;
