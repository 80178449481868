import React from "react";
import "./homebrand.scss";
import { CardBrand } from "../../atoms";
import { ProductData } from "../../../Data/ProductData";
import { useNavigate } from "react-router-dom";
import { Brew, cupcoffee } from "../../../assets";
import { HiEmojiHappy } from "react-icons/hi";

const HomeBrand = () => {
  const navigate = useNavigate();

  const GotoPage = (item) => {
    // window.open(`/product/${item}`, "_blank"); //to open new page //bug g bw data
    navigate(`/menu/${item.slug}`, { state: item });
  };

  return (
    <div className="homebrand-item-container">
      <div className="homebrand-item-content">
        <div className="homebrand-item-content-main">
          {/* <div className="homebrand-item-content-left">
            Coffee shop merupakan suatu tempat yang menyediakan berbagai jenis kopi serta
            minuman non alcohol dengan menyediakan suasana santai, tempat yang nyaman, dan
            dilengkapi berbagai fasilitas seperti alunan musik, tv, bacaan, koneksi internet serta
            desain interior yang menarik dan pelayanan yang ramah. Saat ini coffee shop mengalami pergeseran makna dan menyajikan
            suasanayang nyaman dan fasilitas yang lengkap sehingga menjadi tempat yang tepat
            untuk bersantai dan menghilangkan penat. Selain itu coffee shop juga banyak
            digunakan sebagai tempat pertemuan dengan rekan bisnis dan teman berbagi cerita.
            Budaya minum kopi saat ini sudah menjadi trend yang baru di kalangan
            masyarakat.
            De’ Savour Coffee adalah kedai kopi 
            yang berlokasi di Bekasi, 
            tepatnya di Tambun, Papanmas. 
            Toko ini menyajikan berbagai macam 
            jenis kopi dengan beragam rasa 
            yang lezat dan unik
            Selain itu, Kami juga 
            menawarkan berbagai makanan 
            dan minuman pendamping yang cocok
            untuk menemani secangkir kopi anda.
            </div> */}
          {/* <div className="homebrand-item-content-right">
            <img src={Brew} style={{
            resizeMode: "fit-contain",
            height: "300px",
            position: "contain",
            // padding: (50, 50, 50, 50)
            
            }}/>
          </div> */}
          <div className="homebrand-item-content-list">
            {ProductData.map((item, index) => {
              return (
                <CardBrand
                  key={index}
                  id={item.id}
                  image={item.image}
                  title={item.title}
                  onClick={() => GotoPage(item)}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeBrand;
