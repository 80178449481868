import React from "react";
import { AppRouter } from "./router";
import { Helmet } from "react-helmet";

const App = () => {
  return (
    <>
      <Helmet>
        <title>De Savour Coffee</title>
        <meta name="description" content="De Savour Coffee" />
        <meta name="keywords" content="De Savour Coffee, de savour, coffee, Cafe, beverages, Food, kopi, makanan, minuman kekinian, odeng, oden" />
      </Helmet>
      <AppRouter />
    </>
  );
};

export default App;
