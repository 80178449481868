import React from "react";
import "./cardproduct.scss";
import {
  ImNewXpanderCross,
  ImNewPajero,
  ImNewXpander,
  ImTriton,
  ImOutlanderPhev,
  ImL300,
} from "../../../assets";

const CardProduct = ({ id, slug, image, title, price, subtitle, onClick }) => {
  const Image = () => {
    if (image === "new-xpander-cross") {
      return (
        <img
          src={ImNewXpanderCross}
          className="card-product-item-image"
          alt={`car-${id}`}
        />
      );
    } else if (image === "new-xpander") {
      return (
        <img
          src={ImNewXpander}
          className="card-product-item-image"
          alt={`car-${id}`}
        />
      );
    } else if (image === "new-pajero") {
      return (
        <img
          src={ImNewPajero}
          className="card-product-item-image"
          alt={`car-${id}`}
        />
      );
    } else if (image === "strada-triton") {
      return (
        <img
          src={ImTriton}
          className="card-product-item-image"
          alt={`car-${id}`}
        />
      );
    } else if (image === "outlander-phev") {
      return (
        <img
          src={ImOutlanderPhev}
          className="card-product-item-image"
          alt={`car-${id}`}
        />
      );
    } else if (image === "l-300") {
      return (
        <img
          src={ImL300}
          className="card-product-item-image"
          alt={`car-${id}`}
        />
      );
    } else {
      return (
        <img
          src={ImNewPajero}
          className="card-product-item-image"
          alt={`car-${id}`}
        />
      );
    }
  };

  return (
    <div className="card-product-item">
      <div className="card-product-item-top">
        <Image />
        <div className="card-product-item-title">{title}</div>
        <div className="card-product-item-price">{price}</div>
        <div className="card-product-item-subtitle">{subtitle}</div>
      </div>
      <div className="card-product-item-button-container">
        <div className="card-product-item-button" onClick={onClick}>
          <div className="card-product-item-button-text">Detail</div>
        </div>
      </div>
    </div>
  );
};

export default CardProduct;
