import React from "react";
import "./cardbrand.scss";
import {
  IlFusoCanter,
  IlFusoFighter,
  IlL300,
  IlNewPajero,
  IlNewXpander,
  IlOutlander,
  IlTriton,
  IlXpanderCross,
} from "../../../assets";

const CardBrand = ({ id, image, title, onClick }) => {
  const Image = () => {
    if (image === "new-xpander-cross") {
      return (
        <img
          src={IlXpanderCross}
          className="card-brand-item-image"
          alt={`car-${id}`}
        />
      );
    } else if (image === "new-xpander") {
      return (
        <img
          src={IlNewXpander}
          className="card-brand-item-image"
          alt={`car-${id}`}
        />
      );
    } else if (image === "new-pajero") {
      return (
        <img
          src={IlNewPajero}
          className="card-brand-item-image"
          alt={`car-${id}`}
        />
      );
    } else if (image === "strada-triton") {
      return (
        <img
          src={IlTriton}
          className="card-brand-item-image"
          alt={`car-${id}`}
        />
      );
    } else if (image === "outlander-phev") {
      return (
        <img
          src={IlOutlander}
          className="card-brand-item-image"
          alt={`car-${id}`}
        />
      );
    } else if (image === "l-300") {
      return (
        <img src={IlL300} className="card-brand-item-image" alt={`car-${id}`} />
      );
    } else if (image === "fuso-canter") {
      return (
        <img
          src={IlFusoCanter}
          className="card-brand-item-image"
          alt={`car-${id}`}
        />
      );
    } else if (image === "fuso-fighter") {
      return (
        <img
          src={IlFusoFighter}
          className="card-brand-item-image"
          alt={`car-${id}`}
        />
      );
    } else {
      return (
        <img src={IlL300} className="card-brand-item-image" alt={`car-${id}`} />
      );
    }
  };

  return (
    <div className="card-brand-item" onClick={onClick}>
      <Image />
      {/* <div className="card-brand-item-title">{title}</div> */}
    </div>
  );
};

export default CardBrand;
